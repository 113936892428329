<template>
    <div class="row">
        <div class="col-12">
            <v-alert
                    color="cyan"
                    border="left"
                    elevation="2"
                    colored-border>
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="font-weight-boldest title">{{customBreadcrumb.title}} <small>{{customBreadcrumb.type}}</small></div>
                    <div v-if="customBreadcrumb.pathName">
                        <v-btn @click="goPath" title="Ekle" class="mx-2 bg-success" fab dark small>
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-alert>
        </div>
    </div>

</template>

<script>
export default {
    name: "breadcrumb",
    props:['customBreadcrumb'],
    methods:{
        goPath(){
            // this.$router.push('/');
            // this.$router.push({path: '/areas/create'});
            this.$router.push({name:this.customBreadcrumb.pathName});
        }
    }
}
</script>

<style scoped>
.title{
    font-size: 21px;
}
.title small{
    font-size: 11px;
}
</style>